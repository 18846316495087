.leftMenu {
  &__menu {
    top: 95px;
    max-width: inherit;

    &--opened {
      background: white;
      border-bottom: 1px solid darkgray;
      box-shadow: 0px 55px 50px -50px;
    }
  }
}
